import React, { lazy, Suspense } from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import styled from 'styled-components';

const OfficialLinksPage = () => {
  const links = [
    { name: 'Telegram', url: 'https://t.me/+ECVfs7kY98dlNmYx' },
    { name: 'Discord', url: 'https://discord.com/invite/9-5' },
    { name: 'X', url: 'https://x.com/BASETOKEN9to5' },
    { name: 'Warpcast', url: 'https://warpcast.com/~/channel/9to5' },
    { name: 'Dexscreener', url: 'https://dexscreener.com/base/0x5ffb17d686f6c7fb5105633734e76e632c4a5979' },
    { name: 'Dextools', url: 'https://www.dextools.io/app/en/base/pair-explorer/0x5ffb17d686f6c7fb5105633734e76e632c4a5979' },
  ];
// Create a loading component
const PlaceholderContainer = styled.div`
  height: 100vh; /* Adjust the height as needed */
`;

  return (
    <Suspense fallback={<PlaceholderContainer />}>
    <Container maxWidth="md" className="description-container" sx={{ mt: 16, mb: 2 }}>
      <Typography
        variant="h2"
        component="h1"
        className='text-outline'
        sx={{
          textAlign: 'left',
          fontFamily: 'var(--font-family)',
          fontSize: { xs: '2rem', md: '2.5rem' },
          mb: 4,
          color: 'var(--tertiary-color)',
        }}
      >
        Official Links
      </Typography>
      <Box component="ul" sx={{ 
        listStyleType: 'none', 
        p: 0, 
        m: 0,
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        gap: 3,
      }}>
        {links.map((link, index) => (
          <Box 
            component="li" 
            key={index}
            sx={{
              backgroundColor: 'rgba(255,255,255,0.1)',
              borderRadius: 2,
              p: 2,
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.2)',
                transform: 'translateY(-5px)',
              }
            }}
          >
            <Typography 
              variant="h6" 
              component="div"
              className='text-outline'
              sx={{ 
                fontFamily: 'var(--font-family)',
                color: 'var(--tertiary-color)',
                mb: 1
              }}
            >
              {link.name}
            </Typography>
            <Link 
                          className='text-outline'

              href={link.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              sx={{
                color: 'var(--secondary-color)',
                fontFamily: 'var(--font-family)',
                wordBreak: 'break-all',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                }
              }}
            >
              {link.url}
            </Link>
          </Box>
        ))}
      </Box>
    </Container>
    </Suspense>

  );
};

export default OfficialLinksPage;
