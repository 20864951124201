import React from 'react';
import { Box } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import OfficialLinksPage from './pages/OfficialLinksPage';
import HomePage from './pages/HomePage';
import AssetDownloads from './pages/AssetDownloads';

import { AirstackProvider, useQuery } from "@airstack/airstack-react";

import './styles/App.css';
import './styles/Animations.css';
import './styles/Slider.css';
import './styles/Colors.css';
import './styles/Roadmap.css';
import './styles/Fire.css';
import './styles/Radio.css';


function App() {
  return (
    <AirstackProvider apiKey={"1adef863bc70542508af406e83b063036"}>
    <BrowserRouter>
      <Box display="flex" flexDirection="column">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/official-links" element={<OfficialLinksPage />} />
          <Route path="/asset-downloads" element={<AssetDownloads />} />
        </Routes>
        <Footer />
      </Box>
    </BrowserRouter>
    </AirstackProvider>
  );
}

export default App;