import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Button, 
  Grid, 
  Paper, 
  Modal,
  IconButton,
  useMediaQuery,
  CircularProgress
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const PlaceholderContainer = styled.div`
  height: 100vh; /* Adjust the height as needed */
`;

const AssetDownloader = () => {
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssetLoading, setIsAssetLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const loadAssets = useCallback(() => {
    const context = require.context('../assets/downloads', true, /\.(jpg|jpeg|png|gif|mp4|pdf)$/);
    const assetFiles = context.keys().map(key => ({
      name: key.replace('./', ''),
      url: context(key),
      type: getFileType(key),
    }));

    setAssets(assetFiles);
  }, []);

  useEffect(() => {
    loadAssets();
  }, [loadAssets]);

  const getFileType = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png'].includes(extension)) return 'image';
    if (extension === 'gif') return 'gif';
    if (extension === 'mp4') return 'video';
    return 'other';
  };

  const fetchAssetInfo = async (asset) => {
    try {
      const response = await fetch(asset.url);
      const blob = await response.blob();
      const format = blob.type.split('/')[1].toUpperCase();
      const size = (blob.size / (1024 * 1024)).toFixed(2) + ' MB';
      return { ...asset, format, size, blob: URL.createObjectURL(blob) };
    } catch (error) {
      console.error('Error fetching asset info:', error);
      return asset;
    }
  };

  const downloadAsset = (blobUrl, filename) => {
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderThumbnail = (asset) => {
    switch (asset.type) {
      case 'image':
      case 'gif':
        return <Box component="img" src={asset.url} alt={asset.name} sx={{ width: 50, height: 50, objectFit: 'cover', border: '1px solid black' }} />;
      case 'video':
        return <Box component="video" src={asset.url} sx={{ width: 50, height: 50, objectFit: 'cover', border: '1px solid black' }} />;
      default:
        return <Box sx={{ width: 50, height: 50, bgcolor: 'grey.300' }} />;
    }
  };

  const renderPreview = (asset) => {
    switch (asset.type) {
      case 'image':
      case 'gif':
        return <Box component="img" src={asset.blob} alt={asset.name} sx={{ maxWidth: '100%', maxHeight: '70vh', border: '1px solid black' }} />;
      case 'video':
        return <Box component="video" src={asset.blob} controls sx={{ maxWidth: '100%', maxHeight: '70vh', border: '1px solid black' }} />;
      default:
        return <Typography>Preview not available</Typography>;
    }
  };

  const handleOpenModal = async (asset) => {
    setIsModalOpen(true);
    setSelectedAsset(asset);
    setIsAssetLoading(true);

    try {
      const fullAssetDetails = await fetchAssetInfo(asset);
      setSelectedAsset(fullAssetDetails);
    } catch (error) {
      console.error('Error fetching asset details:', error);
    } finally {
      setIsAssetLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (selectedAsset && selectedAsset.blob) {
      URL.revokeObjectURL(selectedAsset.blob);
    }
    setSelectedAsset(null);
  };

  return (
    <Suspense fallback={<PlaceholderContainer />}>
      <Container             
        style={{marginTop:'7rem',marginBottom:'1rem'}} 
        maxWidth="lg" 
        className='description-container'
      >
        <Typography pb={2} textAlign="left" className="text-outline" color="var(--text-color)" fontFamily="var(--font-family)">
          <p style={{ fontSize:'26px',color: "var(--tertiary-color)" }}>Asset Downloader</p>
        </Typography>
        
        {assets.length === 0 ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="50vh">
            <Typography className='text-outline' fontFamily="var(--font-family)" color="var(--secondary-color)" align="center" marginBottom={2}>
              Loading assets... <br/>This may take a moment, especially on mobile devices.
            </Typography>
            <CircularProgress style={{ color: 'var(--tertiary-color)' }} />
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                {assets.map((asset, index) => (
                  <Paper 
                    key={index} 
                    sx={{ 
                      backgroundColor:"var(--tertiary-color)",
                      p: 2, 
                      mb: 2, 
                      display: 'flex', 
                      alignItems: 'center', 
                      cursor: 'pointer',
                      border: '1px solid black',
                    }}
                    onClick={() => handleOpenModal(asset)}
                  >
                    {renderThumbnail(asset)}
                    <Box sx={{ ml: 2, flexGrow: 1 }}>
                      <Typography color="var(--secondary-color)" fontSize={14} className='text-outline' fontFamily="var(--font-family)" variant="subtitle1"><strong>{asset.name}</strong></Typography>
                    </Box>
                    <Button 
                      className='text-outline'
                      sx={{
                        color: 'var(--secondary-color)',
                        fontFamily:"var(--font-family)",
                        backgroundColor: 'rgba(14, 84, 254, 1)',
                        border: '1px solid black',
                        '&:hover': { 
                          backgroundColor: '#333',
                        },
                      }}                 
                      variant="contained" 
                      size="small"
                      onClick={(e) => { e.stopPropagation(); downloadAsset(asset.url, asset.name); }}
                    >
                      Download
                    </Button>
                  </Paper>
                ))}
              </Box>
            </Grid>
          </Grid>
        )}
  
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
        >
          <Box               
            className='description-container'
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: isMobile ? '90%' : '70%',
              boxShadow: 24,
              p: 4,
              maxHeight: '90vh',
              overflowY: 'auto',
              color:'var(--secondary-color)',
              fontFamily: 'var(--font-family)',
            }}
          >
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color:'var(--secondary-color)',
              }}
            >
              <CloseIcon />
            </IconButton>
            {isAssetLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress style={{ color: 'var(--tertiary-color)' }} />
              </Box>
            ) : selectedAsset && (
              <>
                {renderPreview(selectedAsset)}
                <Typography className='text-outline' variant="h6" sx={{ mt: 2, fontFamily: 'var(--font-family)' }}>{selectedAsset.name}</Typography>
                <Typography className='text-outline' variant="body2" sx={{ fontFamily: 'var(--font-family)' }}>
                  Format: {selectedAsset.format || 'Unknown'} | Size: {selectedAsset.size || 'Unknown'}
                </Typography>
                <Button 
                  className='text-outline'
                  variant="contained"
                  onClick={() => downloadAsset(selectedAsset.blob, selectedAsset.name)}
                  sx={{ 
                    mt: 2,
                    color:'var(--secondary-color)',
                    backgroundColor:'var(--tertiary-color)',
                    fontFamily: 'var(--font-family)',
                    border: '1px solid black',
                    '&:hover': {
                      backgroundColor: '#333',
                    },
                  }}
                >
                  Download
                </Button>
              </>
            )}
          </Box>
        </Modal>
      </Container>
    </Suspense>
  );
};

export default AssetDownloader;