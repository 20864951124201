import { Box, Typography} from '@mui/material';

const Footer = () => {
return (
<Box
  zIndex={100}
  bgcolor="var(--header-footer-color)"
  borderTop={10}
  borderColor="var(--secondary-color)"
  p={2}
  mt="auto"
  textAlign="left"
  px={4}
  py={4}  
>
        <Typography fontFamily='var(--font-family)' color="var(--header-footer-text-color)" variant="body1">9-5 Token</Typography>
        <Typography fontFamily='var(--font-family)' color="var(--header-footer-text-color)" variant="body1">Brand of a Farcaster Community</Typography>
        <Typography fontFamily='var(--font-family)' color="var(--header-footer-text-color)" variant="body1">basetoken9to5@gmail.com</Typography>
        <Typography fontFamily='var(--font-family)' color="var(--header-footer-text-color)" variant="body1">
  Background Image: <a href="https://www.freepik.com" target="_blank" rel="noopener noreferrer">www.freepik.com</a>
</Typography>
        {/* <Typography color="var(--header-footer-text-color)" variant="body1">Copyright © {new Date().getFullYear()} 9-5.sucks - All Rights Reserved.</Typography> */}
      </Box>

);
};

export default Footer;
