import React from 'react';
import styled from 'styled-components';

const LoadingScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const Spinner = styled.div`
  border: 4px solid #333;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingText = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  color: #333;
`;

const LoadingScreen = () => {
  return (
    <LoadingScreenContainer>
      <Spinner />
      <LoadingText>Loading...</LoadingText>
    </LoadingScreenContainer>
  );
};

export default LoadingScreen;