import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';

// Lazy-load components
const Title = lazy(() => import('../components/Title'));
const InfoSlider = lazy(() => import('../components/InfoSlider'));
const Description1 = lazy(() => import('../components/Description1'));
const TokenInfoSlider = lazy(() => import('../components/TokenInfoSlider'));
const TeamSection = lazy(() => import('../components/TeamSection'));
const PartnerSlider = lazy(() => import('../components/PartnerSlider'));
const Description2 = lazy(() => import('../components/Description2'));
const LastSlider = lazy(() => import('../components/LastSlider'));
const Roadmap = lazy(() => import('../components/Roadmap'));
const Instructions = lazy(() => import('../components/Instructions'));
const Divider = lazy(() => import('../components/Divider'));
const OnchainSummer = lazy(() => import('../components/OnchainSummer'));
const ChatBot = lazy(() => import('../components/ChatBot'));
const VideoSection = lazy(() => import('../components/VideoSection'));

const Community = lazy(() => import('../components/Community'));
const Testimonials = lazy(() => import('../components/Testimonials'));
const Airstack = lazy(() => import('../components/Airstack'));
const Leaderboard = lazy(() => import('../components/Leaderboard'));
const Rank = lazy(() => import('../components/Rank'));
const Stories = lazy(() => import('../components/Stories'));
const Wagmi = lazy(() => import('../components/Wagmi'));


// Create a loading component
const PlaceholderContainer = styled.div`
  height: 100vh; /* Adjust the height as needed */
`;
const Home = () => {
  
  return (
    <div>
      <Suspense fallback={<PlaceholderContainer />}>
        <Title />
        <ChatBot />
        <InfoSlider />
        <VideoSection />
        <TokenInfoSlider />

        <TeamSection />
        <OnchainSummer />

        <Description1 /> 

        {/* <Rank />  */}

        <PartnerSlider />
        <Stories />

<Leaderboard />
<Community />
        <LastSlider />



        <Roadmap />

        <Divider />
        <Wagmi />

        <Instructions />

      </Suspense>
    </div>
  );
};

export default Home;