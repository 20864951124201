import { Box, Typography, Link, styled, useMediaQuery, Button } from '@mui/material';
import { useState, useRef,useEffect } from 'react';
import { FaTwitter, FaBars } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faDiscord, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';
import farcasterLogo from '../assets/logos/farcaster_logo.webp';
import dexscreenerLogo from '../assets/logos/dexscreener_logo.webp';
import dextoolsLogo from '../assets/logos/dextools_logo.webp';
import coingeckoLogo from '../assets/logos/cg_black.png';
import xLogo from '../assets/logos/logo-black.png';
import { css } from '@emotion/react';

import logoPng from '../assets/images/Toki3.png';
import baseImg from '../assets/images/Base_Wordmark_Blue.webp';
import radioImg from '../assets/images/radio9to5.png';
import audioSrc from '../assets/audio/9to5.mp3';

import ScrollReveal from 'scrollreveal';

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  backgroundColor: 'var(--header-footer-color)',
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
  },
}));

const FarcasterLogo = styled('img')({ height: '28px', marginLeft: '0.3rem' });
const DexscreenerLogo = styled('img')({ height: '28px', marginLeft: '-0.3rem', marginTop: '0.1rem' });
const DextoolsLogo = styled('img')({ height: '30px', marginLeft: '-0.1rem', marginTop: '0.1rem' });
const CoingeckoLogo = styled('img')({ height: '25px', marginTop: '0.1rem' });
const XLogo = styled('img')({ height: '19px', marginTop: '0.1rem' });


const MenuButton = styled(Box)({ display: 'flex', cursor: 'pointer', color: '#104649', marginRight: '2rem' });

const Header = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(audioSrc));

  const handleTogglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset audio to start
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  useEffect(() => {
    
    ScrollReveal().reveal('.revealTop4', { duration: 2000, distance: '50px', origin: 'top', reset: false,delay: 750  });
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isDesktop = useMediaQuery('(min-width:1025px)');

  return (
    <HeaderContainer className='revealTop4' zIndex={10}>

      <Box
        bgcolor="var(--header-footer-color)"
        color={'var(--header-footer-text-color)'}
        p={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        zIndex={10}
        borderBottom={isDesktop || !isMenuOpen ? 10 : 0}
        borderColor="var(--secondary-color)"
      >
        <Box display="flex" alignItems="center" mx={isDesktop ? 7.5 : 2}>
        <a href="/home">
  <img 
    src={logoPng} 
    alt="Logo" 
    style={{ paddingRight: '1rem', height: isDesktop ? '70px' : '50px' }} 
  />
</a>          <Box display="flex" alignItems="center">
            <Box>
              <Typography variant="h1" sx={{ fontSize: isDesktop ? "20px" : '15px' }}>
                NINE
              </Typography>
              <Typography variant="h1" sx={{ fontSize: isDesktop ? "20px" : '15px' }}>
                TO
              </Typography>
              <Typography variant="h1" sx={{ fontSize: isDesktop ? "20px" : '15px' }}>
                FIVE
              </Typography>
            </Box>
            <img src={baseImg} alt="HeaderImage" style={{ height: isDesktop ? '10.5px' : '10px', marginLeft: isDesktop ? '-1rem' : '-0.7rem' }} />
            <img
        src={radioImg}
        alt="RadioImage"
        className={isPlaying ? 'shake' : 'pulse'}
        style={{
          height: isDesktop ? '45px' : '35.5px',
          marginBottom: '0.5rem',
          cursor: 'pointer',
          marginLeft: '10px'
        }}
        onClick={handleTogglePlay}
      />
          </Box>
        </Box>
        {isDesktop ? (
          <Box mx={7.5}>
            <Box display="flex" alignItems="center">
              <Link fontFamily='var(--font-family)' style={{ color: 'var(--header-footer-text-color', textDecorationColor: 'var(--header-footer-text-color)' }} className="HeaderLink" href="home" target="_blank" alignItems="center" pr={5}>Home</Link>
              {/* <Link fontFamily='var(--font-family)'  style={{ color: 'var(--header-footer-text-color', textDecorationColor: 'var(--header-footer-text-color)' }} className="HeaderLink" href="official-links" target="_blank" alignItems="center" pr={5}>Official Links</Link> */}
              <Link fontFamily='var(--font-family)'  style={{ color: 'var(--header-footer-text-color', textDecorationColor: 'var(--header-footer-text-color)' }} className="HeaderLink" href="asset-downloads" target="_blank" display="block" pr={5}>Asset Downloads</Link>

              <Link className="HeaderLink" href="https://t.me/+ECVfs7kY98dlNmYx" target="_blank" display="flex" alignItems="center" ml={1}>
                <FontAwesomeIcon icon={faTelegram} style={{ color: "var(--header-footer-text-color)", fontSize: '1.5rem' }} />
              </Link>
              <Link href="https://discord.com/invite/9-5" target="_blank" display="flex" alignItems="center" ml={1}>
                <FontAwesomeIcon icon={faDiscord} style={{ color: "var(--header-footer-text-color)", fontSize: '1.5rem' }} />
              </Link>
              <Link href="https://www.instagram.com/9to5base?igsh=ZHk4dzF5b2x6d2p1&utm_source=qr" target="_blank" display="flex" alignItems="center" ml={1}>
                <FontAwesomeIcon icon={faInstagram} style={{ color: "var(--header-footer-text-color)", fontSize: '1.5rem' }} />
              </Link>
              <Link href="https://www.tiktok.com/@9to5base?_t=8oOecwKgss5&_r=1" target="_blank" display="flex" alignItems="center" ml={1}>
                <FontAwesomeIcon icon={faTiktok} style={{ color: "var(--header-footer-text-color)", fontSize: '1.4rem' }} />
              </Link>
              {/* <Link href="https://x.com/BASETOKEN9to5" target="_blank" display="flex" alignItems="center" ml={1}>
                <FaTwitter color="var(--header-footer-text-color)" style={{ fontSize: '1.5rem' }} />
              </Link> */}
              <Link href="https://warpcast.com/~/channel/9to5" target="_blank" display="flex" alignItems="center">
                <FarcasterLogo src={farcasterLogo} alt="Farcaster" />
              </Link>
              <Link href="https://dexscreener.com/base/0x5ffb17d686f6c7fb5105633734e76e632c4a5979" target="_blank" display="flex" alignItems="center" ml={1}>
                <DexscreenerLogo src={dexscreenerLogo} alt="Dexscreener" />
              </Link>
              <Link href="https://www.dextools.io/app/en/base/pair-explorer/0x5ffb17d686f6c7fb5105633734e76e632c4a5979" target="_blank" display="flex" alignItems="center" ml={1}>
                <DextoolsLogo src={dextoolsLogo} alt="Dextools" />
              </Link>
              <Link href="https://www.coingecko.com/de/munze/9-5" target="_blank" display="flex" alignItems="center" ml={1}>
                <CoingeckoLogo src={coingeckoLogo} alt="Coingecko" />
              </Link>
              <Link href="https://x.com/BASETOKEN9to5" target="_blank" display="flex" alignItems="center" ml={1}>
                <XLogo src={xLogo} alt="X" />
              </Link>
            </Box>
          </Box>
        ) : (
          <Box display="flex" mx={-0.75} alignItems="center" position=''>
            <MenuButton onClick={toggleMenu}>
              <FaBars color="var(--header-footer-text-color)" fontSize="1.5rem" />
            </MenuButton>
          </Box>
        )}
      </Box>
      {isMenuOpen && !isDesktop && (
        <Box
          borderBottom={10} bgcolor={'var(--header-footer-color)'} borderColor={'var(--secondary-color)'} display="flex" flexDirection="column" alignItems="flex-end" width="100%">
          <Link fontFamily='var(--font-family)' style={{ color: 'var(--header-footer-text-color', textDecorationColor: 'var(--header-footer-text-color)' }} className="HeaderLink" href="home" target="_blank" display="block" pb={3} pr={3}>Home</Link>
          {/* <Link fontFamily='var(--font-family)'  style={{ color: 'var(--header-footer-text-color', textDecorationColor: 'var(--header-footer-text-color)' }} className="HeaderLink" href="official-links" target="_blank" display="block" pb={3} pr={3}>Official Links</Link> */}
          <Link fontFamily='var(--font-family)'  style={{ color: 'var(--header-footer-text-color', textDecorationColor: 'var(--header-footer-text-color)' }} className="HeaderLink" href="asset-downloads" target="_blank" display="block" pb={3} pr={3}>Asset Downloads</Link>
          <Box display="flex" alignItems="center" pb={3} pr={3}>
            <Link href="https://t.me/+ECVfs7kY98dlNmYx" target="_blank" display="flex" alignItems="center" ml={1}>
              <FontAwesomeIcon icon={faTelegram} style={{ color: "var(--header-footer-text-color)", fontSize: '1.5rem' }} />
            </Link>
            <Link href="https://discord.com/invite/9-5" target="_blank" display="flex" alignItems="center" ml={1}>
              <FontAwesomeIcon icon={faDiscord} style={{ color: "var(--header-footer-text-color)", fontSize: '1.5rem' }} />
            </Link>
            <Link href="https://www.instagram.com/9to5base?igsh=ZHk4dzF5b2x6d2p1&utm_source=qr" target="_blank" display="flex" alignItems="center" ml={1}>
                <FontAwesomeIcon icon={faInstagram} style={{ color: "var(--header-footer-text-color)", fontSize: '1.5rem' }} />
              </Link>
              <Link href="https://www.tiktok.com/@9to5base?_t=8oOecwKgss5&_r=1" target="_blank" display="flex" alignItems="center" ml={1}>
                <FontAwesomeIcon icon={faTiktok} style={{ color: "var(--header-footer-text-color)", fontSize: '1.4rem' }} />
              </Link>
            <Link href="https://warpcast.com/~/channel/9to5" target="_blank" display="flex" alignItems="center">
              <FarcasterLogo src={farcasterLogo} alt="Farcaster" />
            </Link>
            <Link href="https://dexscreener.com/base/0x5ffb17d686f6c7fb5105633734e76e632c4a5979" target="_blank" display="flex" alignItems="center" ml={1}>
              <DexscreenerLogo src={dexscreenerLogo} alt="Dexscreener" />
            </Link>
            <Link href="https://www.dextools.io/app/en/base/pair-explorer/0x5ffb17d686f6c7fb5105633734e76e632c4a5979" target="_blank" display="flex" alignItems="center" ml={1}>
              <DextoolsLogo src={dextoolsLogo} alt="Dextools" />
            </Link>
            <Link href="https://www.coingecko.com/de/munze/9-5" target="_blank" display="flex" alignItems="center" ml={1}>
                <CoingeckoLogo src={coingeckoLogo} alt="Coingecko" />
              </Link>
              <Link href="https://x.com/BASETOKEN9to5" target="_blank" display="flex" alignItems="center" ml={1}>
                <XLogo src={xLogo} alt="X" />
              </Link>
          </Box>
        </Box>
      )}
    </HeaderContainer>
  );
};

export default Header;